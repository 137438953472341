@import "base";

.security-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0px;
  margin: 10px;
}
.security-list img {
  margin-right: 10px;
  vertical-align: middle;
  height: 70px;
}
.security-list > div {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 80px;
  min-width: 250px;
}

